import { createRouter, createWebHistory } from 'vue-router';

import HomeEnglish from './views/HomeEnglish.vue';
import Practice from './views/PracticeEnglish.vue';
import Quiz from './views/QuizEnglish.vue';

const routes = [
  {
    path: '/',
    name: 'Home',
    component: HomeEnglish, // Establecer HomeEnglish como la página principal
  },
  {
    path: '/practice',
    name: 'Practice',
    component: Practice,
  },
  {
    path: '/quiz',
    name: 'Quiz',
    component: Quiz,
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;
