<template>
    <v-app>
      <v-main>
        <v-container>
          <v-card class="mx-auto" max-width="800">
            <!-- Encabezado de la tarjeta -->
            <v-card-title class="title">Mindful Lingua</v-card-title>
            <v-card-text>
              <!-- Selector de nivel de dificultad -->
              <v-select
                v-model="selectedLevel"
                :items="levels"
                label="Select Difficulty Level"
                outlined
                class="mb-4"
              ></v-select>
  
              <!-- Carousel para la selección de tiempo verbal -->
              <v-carousel
                v-model="selectedTenseIndex"
                height="50"
                hide-delimiters
                show-arrows="hover"
                cycle="false"
              >
                <v-carousel-item
                  v-for="(tense, index) in tenses"
                  :key="index"
                  class="carousel-item"
                  @click="generateSentence(tense)"
                >
                  <span>{{ tense }}</span>
                </v-carousel-item>
              </v-carousel>
  
              <v-divider class="my-4"></v-divider>
  
              <!-- Componente de procesamiento -->
              <v-progress-circular
                v-if="loading"
                indeterminate
                color="primary"
                class="mx-auto"
              ></v-progress-circular>
  
              <!-- Mostrar oración generada -->
              <div v-if="generatedSentence">
                <v-subheader>Generated Sentence</v-subheader>
                <v-card-text class="generated-text">
                  <strong>{{ generatedSentence }}</strong>
                </v-card-text>
              </div>
  
              <!-- Entrada de texto para el usuario -->
              <v-textarea
                v-model="userText"
                label="Enter your translation here..."
                outlined
                rows="5"
                dense
              ></v-textarea>
              <v-btn color="primary" @click="correctText" :disabled="!userText">
                Correct and Explain
              </v-btn>
              <v-divider class="my-4"></v-divider>
  
              <!-- Mostrar corrección y explicación -->
              <div v-if="correctedText">
                <v-subheader>Corrected Text</v-subheader>
                <v-card-text class="corrected-text">
                  {{ correctedText }}
                </v-card-text>
              </div>
              <div v-if="explanation">
                <v-subheader>Explanation</v-subheader>
                <v-card-text class="explanation-text">
                  {{ explanation }}
                </v-card-text>
              </div>
            </v-card-text>
          </v-card>
        </v-container>
      </v-main>
    </v-app>
  </template>
  
  <script>
  import axios from "axios";
  
  export default {
    data() {
      return {
        userText: "",
        correctedText: "",
        explanation: "",
        generatedSentence: "",
        selectedTenseIndex: 0,
        selectedLevel: "Basic", // Nivel por defecto
        tenses: [
          "Present Simple",
          "Present Continuous",
          "Present Perfect",
          "Present Perfect Continuous",
          "Past Simple",
          "Past Continuous",
          "Past Perfect",
          "Past Perfect Continuous",
          "Future Simple",
          "Future Continuous",
          "Future Perfect",
          "Future Perfect Continuous",
        ],
        levels: ["Basic", "Intermediate", "Advanced"], // Niveles de dificultad
        loading: false, // Estado de carga
      };
    },
    methods: {
      async generateSentence(tense) {
        if (!tense) return;
  
        // Limpiar el cuadro de texto y las respuestas anteriores
        this.userText = "";
        this.correctedText = "";
        this.explanation = "";
        this.loading = true; // Activar indicador de carga
  
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-3.5-turbo",
              messages: [
                {
                  role: "system",
                  content: `Generate a ${this.selectedLevel.toLowerCase()} level random sentence in ${tense.toLowerCase()} tense in Spanish.`,
                },
              ],
              max_tokens: 50,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
              },
            }
          );
  
          this.generatedSentence =
            response.data.choices[0].message.content.trim();
        } catch (error) {
          console.error("Error fetching sentence from OpenAI API", error);
          this.generatedSentence = "Error fetching sentence.";
        } finally {
          this.loading = false; // Desactivar indicador de carga
        }
      },
  
      async correctText() {
        if (!this.userText) {
          this.correctedText = "";
          this.explanation = "Por favor ingresa algún texto para corregir.";
          return;
        }
  
        this.loading = true; // Activar indicador de carga
  
        try {
          const response = await axios.post(
            "https://api.openai.com/v1/chat/completions",
            {
              model: "gpt-4",
              messages: [
                {
                  role: "system",
                  content:
                    "You are a helpful assistant that corrects English text and explains the corrections in Spanish. Provide the corrected text first, followed by 'Explicación:' and then the explanation.",
                },
                {
                  role: "user",
                  content: `Correct the following text and explain the corrections in Spanish: ${this.userText}`,
                },
              ],
              max_tokens: 300,
            },
            {
              headers: {
                "Content-Type": "application/json",
                Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
              },
            }
          );
  
          const aiResponse = response.data.choices[0].message.content;
          const [corrected, explanation] = aiResponse.split("Explicación:");
          this.correctedText = corrected.trim();
          this.explanation = explanation
            ? explanation.trim()
            : "No se proporcionó una explicación.";
        } catch (error) {
          console.error("Error fetching from OpenAI API", error);
          this.correctedText = "";
          if (error.response && error.response.status === 401) {
            this.explanation =
              "Error de autenticación. Verifica tu clave API de OpenAI.";
          } else {
            this.explanation =
              "Hubo un error al procesar tu solicitud. Por favor, intenta nuevamente.";
          }
        } finally {
          this.loading = false; // Desactivar indicador de carga
        }
      },
    },
  };
  </script>
  
  <style>
  @import "~vuetify/styles"; /* Asegúrate de importar los estilos de Vuetify */
  
  .v-application {
    font-family: "Roboto", sans-serif;
    background-color: #f5f5f5;
  }
  
  .v-card {
    margin-top: 20px;
    padding: 20px;
  }
  
  .v-btn {
    margin-top: 20px;
  }
  
  .v-divider {
    margin-top: 20px;
  }
  
  .generated-text {
    margin-bottom: 16px;
    font-weight: bold;
    color: #1e88e5; /* Color azul para destacar */
  }
  
  .corrected-text {
    margin-bottom: 14px;
    color: #388e3c; /* Color verde para correcciones */
    font-weight: bold;
  }
  
  .explanation-text {
    margin-bottom: 10px;
    color: #242323; /* Color para las explicaciones */
    font-weight: bold;
  }
  
  .title {
    font-size: 20px;
    color: white;
    background-color: #3f51b5;
    text-align: center;
    padding: 10px;
    border-radius: 4px;
  }
  
  .carousel-item {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100px;
    color: #3f51b5;
    font-weight: bold;
    text-align: center;
    font-size: 16px;
    padding: 0 10px;
    word-wrap: break-word;
    white-space: normal; /* Permitir saltos de línea */
  }
  
  @media (max-width: 600px) {
    .v-card {
      margin-top: 10px;
      padding: 10px;
    }
  
    .title {
      font-size: 18px;
      padding: 8px;
    }
  
    .carousel-item {
      font-size: 18px;
    }
  }
  </style>
  