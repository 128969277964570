<template>
  <v-app>
    <v-main>
      <v-container>
        <v-card class="mx-auto" max-width="800">
          <v-card-title class="title">Mindful Lingua</v-card-title>
          <v-card-text>
            <v-select
              v-model="selectedLevel"
              :items="levels"
              label="Select Difficulty Level"
              outlined
              class="mb-4"
            ></v-select>

            <v-textarea
              v-model="topic"
              label="Enter a topic of your interest."
              outlined
              rows="2"
              class="mb-4"
            ></v-textarea>
            <v-btn block
              color="primary"
              @click="generateQuiz"
              :disabled="!topic"

            >
              Generate Quiz
            </v-btn>
            <!-- Botón para Comprensión de Lectura -->
            <v-btn block
              
            color="secondary"
              @click="generateReadingComprehension"
              :disabled="!topic"
            >
              Reading Comprehension
            </v-btn>

            <v-divider class="my-4"></v-divider>

            <v-progress-circular
              v-if="loading"
              indeterminate
              color="primary"
              class="mx-auto"
            ></v-progress-circular>

            <!-- Mostrar preguntas de comprensión de lectura -->
            <div v-if="readingParagraph">
              <v-card outlined class="mb-4">
                <v-card-title class="question-text">Reading Passage</v-card-title>
                <v-card-text class="reading-text">{{ readingParagraph }}</v-card-text>
              </v-card>
            </div>

            <div v-if="questions.length > 0">
              <div v-for="(question, idx) in questions" :key="idx" class="mb-4">
                <v-card outlined>
                  <v-card-text class="question-text">{{ idx + 1 }}. {{ question.question }}</v-card-text>
                  <v-card-text>
                    <v-radio-group v-model="answers[idx]">
                      <v-radio
                        v-for="(option, optIdx) in question.options"
                        :key="optIdx"
                        :label="option"
                        :value="option"
                        :class="getOptionClass(option, question.correctAnswer, answers[idx])"
                      ></v-radio>
                    </v-radio-group>
                  </v-card-text>
                </v-card>
              </div>
              <v-btn
                color="success"
                @click="evaluateQuiz"
              >
                Evaluate Quiz
              </v-btn>
            </div>

            <v-divider class="my-4"></v-divider>

            <div v-if="score !== null">
              <v-subheader>Quiz Result</v-subheader>
              <v-card-text class="result-text">
                Your score: {{ score }} / {{ questions.length }}
              </v-card-text>
            </div>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import axios from "axios";

export default {
  data() {
    return {
      topic: "",
      selectedLevel: "Basic", // Nivel por defecto
      levels: ["Basic", "Intermediate", "Advanced"],
      questions: [],
      answers: [],
      score: null,
      loading: false,
      showIncorrectAnswers: false, // Estado para mostrar respuestas incorrectas después de la evaluación
      readingParagraph: "", // Almacena el párrafo de lectura
    };
  },
  watch: {
    topic(newTopic) {
      if (!newTopic) {
        this.clearQuizAndReading();
      }
    }
  },
  methods: {
    clearQuizAndReading() {
      this.questions = [];
      this.answers = [];
      this.score = null;
      this.readingParagraph = "";
      this.showIncorrectAnswers = false;
    },
    async generateQuiz() {
      this.loading = true;
      this.clearQuizAndReading();

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content: `You are a quiz generator. Create a quiz with exactly 10 multiple-choice questions about the topic "${this.topic}" in English. The quiz should be at a ${this.selectedLevel.toLowerCase()} level. Each question should have exactly 3 options, labeled as a), b), and c), with one clearly indicated correct answer. Ensure the format is as follows:
                
                1. Question: [question text]
                   a) [option 1]
                   b) [option 2]
                   c) [option 3]
                   Correct: [correct option letter]
                   
                2. Question: [question text]
                   a) [option 1]
                   b) [option 2]
                   c) [option 3]
                   Correct: [correct option letter]
                   
                ...
                
                10. Question: [question text]
                   a) [option 1]
                   b) [option 2]
                   c) [option 3]
                   Correct: [correct option letter]`
              },
            ],
            max_tokens: 1500,
            temperature: 0.7,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
            },
          }
        );

        console.log("API Response:", response.data); // Log the API response

        const questionsData = response.data.choices[0]?.message?.content.trim().split(/\d+\.\sQuestion:/).filter(Boolean);

        if (questionsData && questionsData.length >= 10) {
          this.questions = questionsData.slice(0, 10).map((line) => {
            const questionMatch = line.match(/(.*?)\s+a\)/);
            const optionsMatch = line.match(/a\)\s*(.*?)\s+b\)\s*(.*?)\s+c\)\s*(.*?)\s+Correct:/);
            const correctMatch = line.match(/Correct:\s*(\w)/);

            const options = optionsMatch ? [optionsMatch[1].trim(), optionsMatch[2].trim(), optionsMatch[3].trim()] : [];
            const correctIndex = correctMatch ? correctMatch[1].charCodeAt(0) - 'a'.charCodeAt(0) : -1;
            const correctAnswer = options[correctIndex] || '';

            return {
              question: questionMatch ? questionMatch[1].trim() : '',
              options,
              correctAnswer
            };
          });

          // Log questions and answers for debugging
          console.log("Questions and Answers from API:", this.questions);

          // Inicializar respuestas
          this.answers = Array(this.questions.length).fill(null);
        } else {
          throw new Error("Invalid API response");
        }

      } catch (error) {
        console.error("Error fetching quiz from OpenAI API", error);
        this.questions = [];
      } finally {
        this.loading = false;
      }
    },
    async generateReadingComprehension() {
      this.loading = true;
      this.clearQuizAndReading();

      try {
        const response = await axios.post(
          "https://api.openai.com/v1/chat/completions",
          {
            model: "gpt-3.5-turbo",
            messages: [
              {
                role: "system",
                content: `You are a reading comprehension generator. Provide a passage at a ${this.selectedLevel.toLowerCase()} level about the topic "${this.topic}" in English. Then create exactly 5 comprehension questions that are answered with "True" or "False" based on the passage. Make sure the questions require inferential thinking rather than recalling exact phrases from the passage. Format as follows:
                
                Passage: [passage text]
                
                1. Question: [Inferential True or False question]
                   Correct: [True or False]
                   
                ...
                
                5. Question: [Inferential True or False question]
                   Correct: [True or False]`
              },
            ],
            max_tokens: 1500,
            temperature: 0.7,
          },
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${process.env.VUE_APP_OPENAI_API_KEY}`,
            },
          }
        );

        console.log("Reading Comprehension API Response:", response.data);

        const responseData = response.data.choices[0]?.message?.content.trim();

        const passageMatch = responseData.match(/Passage:\s*(.+)\n\n1\./s);
        const questionsData = responseData.split(/\d+\.\sQuestion:/).slice(1);

        if (passageMatch && questionsData.length >= 5) {
          this.readingParagraph = passageMatch[1].trim();

          this.questions = questionsData.slice(0, 5).map((line) => {
            const questionMatch = line.match(/(.*?)\s+Correct:/);
            const correctMatch = line.match(/Correct:\s*(True|False)/);

            const options = ["True", "False"];
            const correctAnswer = correctMatch ? correctMatch[1] : '';

            return {
              question: questionMatch ? questionMatch[1].trim() : '',
              options,
              correctAnswer
            };
          });

          console.log("Reading Questions and Answers from API:", this.questions);

          // Inicializar respuestas
          this.answers = Array(this.questions.length).fill(null);
        } else {
          throw new Error("Invalid API response");
        }

      } catch (error) {
        console.error("Error fetching reading comprehension from OpenAI API", error);
        this.readingParagraph = "";
        this.questions = [];
      } finally {
        this.loading = false;
      }
    },
    evaluateQuiz() {
      this.score = this.answers.reduce((score, answer, index) => {
        const isCorrect = answer === this.questions[index].correctAnswer;
        return score + (isCorrect ? 1 : 0);
      }, 0);
      this.showIncorrectAnswers = true; // Mostrar respuestas incorrectas después de la evaluación
    },
    getOptionClass(option, correctAnswer, selectedAnswer) {
      if (!this.showIncorrectAnswers) return '';
      if (option === selectedAnswer && option !== correctAnswer) {
        return 'incorrect-option'; // Resalta las respuestas incorrectas en rojo
      }
      if (option === selectedAnswer && option === correctAnswer) {
        return 'correct-option'; // Resalta las respuestas correctas en verde
      }
      return '';
    }
  }
};
</script>

<style>
.title {
  font-size: 20px;
  color: white;
  background-color: #3f51b5;
  text-align: center;
  padding: 10px;
  border-radius: 4px;
}

.result-text {
  font-weight: bold;
  color: #388e3c; /* Color verde para el resultado */
}

.v-radio-group {
  margin-left: 10px;
}

.incorrect-option {
  background-color: #ffebee; /* Rojo claro para respuestas incorrectas */
}

.correct-option {
  background-color: #e8f5e9; /* Verde claro para respuestas correctas */
}

.v-card-text {
  white-space: pre-wrap; /* Asegura que el texto se ajuste correctamente */
  overflow-wrap: break-word; /* Permite que las palabras largas se dividan */
  font-weight: bold; /* Hace el texto en negritas */
  font-size: 18px; /* Aumenta el tamaño del texto */
}

.question-text {
  white-space: pre-wrap; /* Asegura que el texto se ajuste correctamente */
  overflow-wrap: break-word; /* Permite que las palabras largas se dividan */
  font-weight: bold; /* Hace el texto en negritas */
  font-size: 18px !important;
  display: block; /* Asegura que el texto ocupe todo el ancho del contenedor */
  padding: 10px; /* Añade espacio alrededor del texto */
  line-height: 1.4; /* Aumenta el espaciado entre líneas para mejorar la legibilidad */
}

.reading-text {
  white-space: pre-wrap; /* Asegura que el texto se ajuste correctamente */
  overflow-wrap: break-word; /* Permite que las palabras largas se dividan */
  font-size: 16px; /* Tamaño estándar para el texto del párrafo */
  line-height: 1.6; /* Aumenta el espaciado entre líneas para mejorar la legibilidad */
  padding: 10px; /* Añade espacio alrededor del texto */
}
</style>
