<template>
  <v-app>
    <v-main>
      <v-container>
        <!-- Contenido de la página de inicio -->
        <v-card class="mx-auto" max-width="800">
          <v-card-title class="title">Mindful Lingua</v-card-title>
          <v-card-text>
            <router-link to="/quiz" class="link">Quiz</router-link>
            <router-link to="/practice" class="link">Practice</router-link>
          </v-card-text>
        </v-card>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
export default {
  name: 'HomeEnglish', // Cambiar a un nombre multi-palabra
};
</script>

<style>
.title {
  font-size: 24px;
  color: #3f51b5;
  text-align: center;
  margin-bottom: 20px;
}

.link {
  display: inline-block;
  margin: 10px;
  font-size: 18px;
  color: #1e88e5;
  text-decoration: none;
}

.link:hover {
  text-decoration: underline;
}
</style>
